@font-face {
  font-family: 'Source Sans';
  src: url('./fonts/SourceSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url('./fonts/Kanit.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Apply the custom font to the entire body */
body {
  font-family: 'Source Sans', sans-serif;
}

.logo-font {
  font-family: 'Kanit', sans-serif;
}

.logo-subheader-font {
  font-family: 'Source Sans', sans-serif;
}

/* Parallax Background Container */
.background-container {
  background-image: url('./images/la-background.png');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* For mobile devices, adjust background-attachment to scroll instead of fixed */
@media (max-width: 768px) {
  .background-container {
    background-attachment: scroll; /* Prevents issues with fixed backgrounds on mobile */
    background-size: cover; /* Ensure it covers the entire section */
    height: 100vh;
  }
}

/* Logo Styles */
.luxe-logo {
  max-width: 300px;
  width: 100%;
  height: auto;
  background-color: transparent;
}

/* Package Section */
.package-img {
  height: 250px; /* Limit the image height */
  object-fit: cover; /* Ensure the images are cropped properly */
}

/* Promo Pricing Styles */
.card-footer {
  background-color: transparent; /* Make footer transparent to blend with the card */
}

.original-price {
  text-decoration: line-through;
  color: #999;
  font-size: 0.8rem;
  margin-right: 0px;
}

.promo-price {
  color: #e63946;
  font-size: 1.2rem;
  font-weight: bold;
  transform: rotate(5deg); /* Slight angle */
  display: inline-block;
}

.service-section {
  margin-top: 20px;
}

.promo-vip {
  transform: rotate(3deg); /* Slight angle for VIP */
}

/* VIP Package Styling */
.vip-package {
  border: 1px solid rgba(0,0,0,.125); /* Gray border to match other packages */
  width: 100%; /* Full width of the container */
}

.vip-images {
  display: flex;
}

.vip-images img {
  width: 33.33%; /* Remove spacing between the 3 images */
  height: 250px;
  object-fit: cover;
}

.col-md-4 {
  margin-top: 10px; /* Remove padding from the columns */
}

/* Button Styling */
.availability-button {
  font-family: 'Source Sans', sans-serif;
  font-size: 1.2rem;
  color: white;
  background-color: #007bff; /* Blue background */
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.availability-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Content Below the Background */
.content-below {
  padding: 40px;
  margin-top: 20px;
  background-color: #333333;
  color: white;
  text-align: center;
}

.content-below h1 {
  margin-top: 0;
  font-size: 0.8rem;
}

.low-margin {
  margin-bottom: 0px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.icon {
  width: 50px;
  height: 50px;
  margin: 0 15px;
  transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.2);
}

.video-section {
  background-color: #333;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 0px 0;
  overflow: hidden;
}

.promo-video {
  max-width: 100%;
  height: auto; /* Default for mobile devices */
}

/* Media query for tablets and larger screens */
@media (min-width: 768px) {
  .promo-video {
    height: 700px; /* Fixed height for tablets and larger screens */
    max-width: 100%;
  }
}